import { FC, useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, CircularProgress } from "@mui/material";
import VanCard from "./item";
import Aside from "src/components/Aside";
import NoResult from "src/components/Items/NoResult";
import PreviewPageVan from "src/components/Items/previewPages/PreviewVan";
import InfiniteScrollNearVan from "./InfiniteScrollList/InfiniteScrollNearVan";
import InfiniteScrollList from "src/pages/Van/InfiniteScrollList";
import { useTypedSelector } from "src/hooks/useTypeSelector";
import useIsMobile from "src/hooks/useIsMobile";
import { useActions } from "src/hooks/useActions";
import { getNames, scrollToTop } from "src/common/utils/others";
import { IVanFetch } from "src/models/IVan";
import Icons from "src/common/icons";
import "./styles.scss";

const Van: FC = () => {

  const {
    location: { locationList },
    subLocation: { sublocationList },
    van: { vanList, isLoading, filters, errors, isFilterValidate },
  } = useTypedSelector((state) => state);
  const subIsLoading = useTypedSelector((state) => state.subLocation.isLoading);

  const isMobile = useIsMobile();
  const { setLocationFilter, setRegionFilter } = useActions();
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(10);
  const [showNearVans, setShowNearVans] = useState(1);
  const [loadingNearVans, setLoadingNearVans] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [mainLocation, setMainLocation] = useState('');

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.pageYOffset > 120 ? setShowButton(true) : setShowButton(false);
    });
  }, []);

  useEffect(() => {
    if (filters.location && !subIsLoading.all) {
      const location = locationList.find((el) => el.name === filters.location);
      if (location) {
        setMainLocation(location.name);
        const subs = location.sub_location.map((sub) => sub.name);
        subs.length ? setTags([location.name, ...subs]) : setTags([]);
      } else if (locationList.length && sublocationList.length) {
        const sub = sublocationList.filter((el) => el.name === filters.location);
        const location = locationList.filter((el) => el.name === sub[0].main_location_name);
        setMainLocation(location[0].name);
        const subs = location[0].sub_location.map((sub) => sub.name);
        subs.length ? setTags([location[0].name, ...subs]) : setTags([]);
      }
      if (location?.tags?.length) {
        const regions = location?.tags.filter((tag) => tag.category === 'region');
        setRegionFilter(getNames(regions));
      } else setRegionFilter([]);
    }
  }, [filters.location, locationList, sublocationList]);

  const renderItem = (van: IVanFetch) => {
    return (
      <VanCard
        van={van}
        key={van.id}
        errors={errors}
        isFilterValidate={isFilterValidate}
      />
    );
  };

  return (
    <>
      <div className="aside">
        <Aside />
      </div>
      <div className="vans-time">
        {tags.length ? (
          <div className='filter-sub'>
            {tags.map((sub) => (
              <div
                className={
                  `sub-locations__item ${(
                    (filters.location === sub && !filters.sub_location) ||
                    filters.sub_location === sub) && 'filter-sub__selected'
                  }`
                }
                style={{ cursor: 'pointer' }}
                onClick={() => setLocationFilter(
                  mainLocation, sub === mainLocation ? '' : sub
                )}
              >
                {sub === filters.location ? 'All' : sub}
              </div>
            ))}
          </div>
        ) : <></>}
        {!isLoading.all ? (
          Object.keys(vanList).length ? (
            <>
              {/* {filters.location ? (
                <>
                  {(vanList[filters.location] && vanList[filters.location].length) ? (
                    <div className="vans">
                      <InfiniteScroll
                        dataLength={show}
                        next={() => setLoading(true)}
                        hasMore={true}
                        className='infiniteScroll'
                        loader={loading &&
                          <div className='infiniteScroll__loading'>
                            <CircularProgress size={100} />
                          </div>
                        }
                      >
                        {vanList[filters.location!].length &&
                          <InfiniteScrollList
                            data={vanList[filters.location!]}
                            renderItem={renderItem}
                            loading={loading}
                            setLoading={setLoading}
                            show={show}
                            setShow={setShow}
                          />
                        }
                      </InfiniteScroll>
                    </div>
                  ) : (
                    <NoResult margin='30px auto' />
                  )}
                  <div className="vans">
                    {(vanList[filters.location] && (show >= vanList[filters.location].length)) && (
                      <InfiniteScroll
                        dataLength={showNearVans}
                        next={() => setLoadingNearVans(true)}
                        hasMore={true}
                        className='infiniteScroll'
                        loader={loading &&
                          <div className='infiniteScroll__loading'>
                            <CircularProgress size={100} />
                          </div>
                        }
                      >
                        <InfiniteScrollNearVan
                          data={Object.keys(vanList)}
                          vans={vanList}
                          renderItem={renderItem}
                          loading={loadingNearVans}
                          setLoading={setLoadingNearVans}
                          show={showNearVans}
                          setShow={setShowNearVans}
                          filterLocation={filters.location}
                        />
                      </InfiniteScroll>
                    )}
                  </div>
                </>
              ) : ( */}
              {Object.keys(vanList).map(location => (
                <div className="vans">
                  <InfiniteScroll
                    dataLength={show}
                    next={() => setLoading(true)}
                    hasMore={true}
                    className='infiniteScroll'
                    loader={loading &&
                      <div className='infiniteScroll__loading'>
                        <CircularProgress size={100} />
                      </div>
                    }
                  >
                    <InfiniteScrollList
                      data={vanList[location]}
                      renderItem={renderItem}
                      loading={loading}
                      setLoading={setLoading}
                      show={show}
                      setShow={setShow}
                    />
                  </InfiniteScroll>
                </div>
              ))}
              {/* )} */}
            </>
          ) : (
            <NoResult margin='120px auto 0' />
          )
        ) : (
          <PreviewPageVan />
        )}
      </div>
      {isMobile && showButton && (
        <Button
          onClick={scrollToTop}
          className="back-to-top"
          children={<Icons.UpArrow />}
        />
      )}
    </>
  );
};
export default Van;
