import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Filters from '..';
import { setMainFilter } from 'src/common/utils/setFilters';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { useActions } from 'src/hooks/useActions';
import { getNames } from 'src/common/utils/others';
import './styles.scss';

interface IProps {
  marginTop: number;
  setMarginTop: (arg0: number) => void
}

const GeneralFilter: FC<IProps> = ({ marginTop, setMarginTop }) => {

  // const { setLocationFilter, setRegionFilter } = useActions();
  const [params, setSearchParams] = useSearchParams();
  const anchorRef = useRef<HTMLDivElement>(null);
  const isLaptop = useMemo(() => window.outerWidth < 1261, [window.outerWidth]);
  // const [tags, setTags] = useState<string[]>([]);
  // const [mainLocation, setMainLocation] = useState('');

  const {
    location: { locationList },
    subLocation: { sublocationList, isLoading },
    van: { filters, checkFilter },
  } = useTypedSelector((state) => state);

  const handleSetFilter = () => {
    setMainFilter(params, filters);
    setSearchParams(params);
  };

  // useEffect(() => {
  //   if (filters.location && !isLoading.all) {
  //     const location = locationList.find((el) => el.name === filters.location);
  //     if (location) {
  //       setMainLocation(location.name);
  //       const subs = location.sub_location.map((sub) => sub.name);
  //       subs.length ? setTags([location.name, ...subs]) : setTags([]);
  //     } else if (locationList.length && sublocationList.length) {
  //       const sub = sublocationList.filter((el) => el.name === filters.location);
  //       const location = locationList.filter((el) => el.name === sub[0].main_location_name);
  //       setMainLocation(location[0].name);
  //       const subs = location[0].sub_location.map((sub) => sub.name);
  //       subs.length ? setTags([location[0].name, ...subs]) : setTags([]);
  //     }
  //     if (location?.tags?.length) {
  //       const regions = location?.tags.filter((tag) => tag.category === 'region');
  //       setRegionFilter(getNames(regions));
  //     } else setRegionFilter([]);
  //   }
  // }, [filters.location, locationList, sublocationList]);

  useEffect(() => {
    handleSetFilter();
  }, [filters]);

  // useEffect(() => {
  //   const height = anchorRef!.current!.getBoundingClientRect().height;
  //   isLaptop && setMarginTop(height + 45);
  // }, [anchorRef, mainLocation]);

  useEffect(() => {
    const height = anchorRef!.current!.getBoundingClientRect().height;
    isLaptop && setMarginTop(height + 45);
  }, [anchorRef]);

  let prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    const currentScrollPos = window.pageYOffset;
    if (anchorRef.current) {
      if (prevScrollpos > currentScrollPos) {
        anchorRef.current!.style.top = "38px";
      } else {
        (prevScrollpos > 120) && (
          anchorRef.current!.style.top = `-${marginTop}px`
        );
      }
      prevScrollpos = currentScrollPos;
    }
  };

  return (
    <div ref={anchorRef} className='general-filter-container'>
      <div className='general-filter'>
        <div className='general-filter__locations'>
          <Filters.Location check={checkFilter} />
          <Filters.AnotherLocation check={checkFilter} />
        </div>
        <Filters.Times check={checkFilter} />
      </div>
      {/* <div className='filter-sub'>
        {tags.map((sub) => (
          <div
            className={
              `sub-locations__item ${(
                (filters.location === sub && !filters.sub_location) ||
                filters.sub_location === sub) && 'filter-sub__selected'
              }`
            }
            style={{ cursor: 'pointer' }}
            onClick={() => setLocationFilter(
              mainLocation, sub === mainLocation ? '' : sub
            )}
          >
            {sub}
          </div>
        ))}
      </div> */}
    </div>

  );
};

export default GeneralFilter;
