import { FC } from 'react';
import { DateTime } from 'luxon';
import { Tooltip } from '@mui/material';
import { IOrderForClient } from 'src/models/IOrder';
import './styles.scss';

interface IProps {
  order: IOrderForClient
}

const CommonCard: FC<IProps> = ({ order }) => {

  const {
    created_at,
    timeOffset,
    start_date,
    location,
    sub_location,
    end_date,
    end_location,
    sub_location_end,
    delivery_address,
    return_at
  } = order;

  const timeObject = DateTime.fromISO(end_date)
    .diff(DateTime.fromISO(start_date), ['days', 'hours'])
    .toObject();

  return (
    <div className='common-card'>
      <div className='client__order1__main__header'>
        <h4>Dates Times Location</h4>
      </div>
      <div className='common-card__container'>
        <h5>
          <b>Booked:</b>
          {
            DateTime.fromISO(created_at, { zone: "UTC" })
              .plus({ minutes: timeOffset }).toFormat("ff")
          }
        </h5>
        <h5>
          <b>From:</b>
          {
            `${DateTime.fromISO(start_date, { zone: "UTC" })
              .plus({ minutes: timeOffset }).toFormat("ff")} 
                  | ${location} ${sub_location ? ` | ${sub_location}` : ''
            }`
          }
        </h5>
        <h5>
          <b>To:</b>
          {
            `${DateTime.fromISO(end_date, { zone: "UTC" })
              .plus({ minutes: timeOffset }).toFormat("ff")} 
                  | ${end_location || location} ${sub_location_end ? ` | ${sub_location_end}` : ''
            }`
          }
        </h5>
        <h5>
          <b>Duration:</b>
          {
            `${timeObject.days}days  ${Math.ceil(
              timeObject.hours as number
            )}hours`
          }
        </h5>
        {delivery_address && (
          <h5>
            <b>Deliver to:</b>
            {delivery_address}
          </h5>
        )}
        {return_at || delivery_address && (
          <h5>
            <b>Return at:</b>
            {return_at ? return_at : delivery_address && "same as delivery"}
          </h5>
        )}
      </div>
      <div className='common-card__change'>
        <Tooltip title='Redirect to Gmail'>
          <a
            href={`https://mail.google.com/mail/?view=cm&source=mailto&to=${order.van?.host.email}&su=van rental number ${order.id}`}
            target='_blank'
          >
            Email us
          </a>
        </Tooltip>
        to request a change
      </div>
    </div>
  );
};

export default CommonCard;
