import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { DateTime } from 'luxon';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SmallHeader from '../Van/singleVanPage/SmallHeader';
import OrderCard from './components/OrderCard';
import Footer from 'src/components/Footer';
import SmallFooter from 'src/components/Footer/SmallFooter';
import DocumentsModal from './components/DocumentsModal';
import DeleteModal from 'src/components/Items/DeleteModal';
import CancelModal from './components/CancelModal';
import LoginModal from 'src/components/Header/LoginModal';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IDriverFetch } from 'src/models/IDriver';
import Check from '../Van/item/CheckIcon';
import './styles.scss';

const ClientPage: FC = () => {

  const { getClient, errorLogin, clearClientResult } = useActions();
  const navigate = useNavigate();
  const {
    client: { client, orderList, isLoading, result },
    driver: { driver }
  } = useTypedSelector((state) => state);
  const resultCancel = useTypedSelector((state) => state.cancelOrder.result);
  const token = localStorage.getItem("guest-token");

  const [openDocs, setOpenDocs] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openDriver, setOpenDriver] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [drivers, setDrivers] = useState<IDriverFetch[] | null>();

  useEffect(() => {
    if (token) {
      const { id } = jwtDecode(token!) as { [key: string]: any };
      if (id) getClient(id);
      else {
        errorLogin();
        navigate('/');
      }
    } else {
      errorLogin();
      navigate('/');
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const { id } = jwtDecode(token!) as { [key: string]: any };
      if (id) {
        (result.update || result.order || resultCancel || driver) && (
          getClient(id)
        );
      } else {
        errorLogin();
        navigate('/');
      }
    } else {
      errorLogin();
      navigate('/');
    }
  }, [result.update, result.order, resultCancel, driver]);

  useEffect(() => {
    result.pay && (
      window.open(`${result!.pay}`, '_blank', 'noopener,noreferrer'),
      clearClientResult()
    );
  }, [result.pay]);

  useEffect(() => {
    if (result.doc?.base64) {
      if (result.doc.fileType === 'application/pdf') {
        const pdfWindow = window.open('');
        pdfWindow!.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          encodeURI(result.doc?.base64) + "'></iframe>"
        );
      } else {
        const cw = window.open();
        cw!.document.write(
          `<img src="data:image/png;base64, ${result.doc?.base64}"/>`
        );
      }
    }
  }, [result.doc]);

  const handleOpenDriver = (orderId: number, driverId: number) => {
    const selectedOrder = orderList!.filter((order) => order.id === orderId);
    const newDriverList = selectedOrder[0].additional_driver.filter(
      (driver) => driver.id !== driverId
    );
    setDrivers(newDriverList);
    setSelectedId(orderId);
    setOpenDriver(true);
  };

  const handleOpenDocs = (id: number) => {
    setSelectedId(id);
    setOpenDocs(true);
  };

  const handleOpenCancel = (id: number) => {
    setSelectedId(id);
    setOpenCancel(true);
  };

  return (
    <>
      <div className='header'>
        <SmallHeader />
      </div>
      <div className='client'>
        {isLoading.get ? (
          <div className='loading-container'>
            <CircularProgress size={100} />
          </div>
        ) : (
          <>
            {(orderList?.length && client) ? (
              <>
                <div className='client__orders'>
                  <h3>Upcoming Trip:</h3>
                  <h4 className='client__orders__subtitle'>
                    See All Trips Below
                  </h4>
                  {orderList.map((order, key) => (
                    <Accordion key={key}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <div className='client__order1__accordion'>
                          <h5># {order.id}</h5>
                          <div className='client__order1__accordion__container'>
                            <div className={`client__order1__header__badge__status ${order.status}`}>
                              {order.status}
                            </div>
                            <div className='client__order1__accordion__info'>
                              <div>
                                <div>{order.location}</div>
                                <div>
                                  {DateTime.fromISO(order.start_date, { zone: 'UTC' })
                                    .plus({ minutes: order.timeOffset }).toFormat('ff')}
                                </div>
                              </div>
                              -
                              <div>
                                <div>{order.end_location || order.location}</div>
                                <div>
                                  {DateTime.fromISO(order.end_date, { zone: 'UTC' })
                                    .plus({ minutes: order.timeOffset }).toFormat('ff')}
                                </div>
                              </div>
                            </div>
                            <div className='client__order1__accordion__note'>
                              {
                                order.note.length > 20
                                  ? `${order.note.slice(0, 20)}...`
                                  : order.note.slice(0, 20)
                              }
                            </div>
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <OrderCard
                          order={order}
                          handleOpenDriver={handleOpenDriver}
                          handleOpenDocs={handleOpenDocs}
                          handleOpenCancel={handleOpenCancel}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
                <DocumentsModal
                  handleClose={() => setOpenDocs(false)}
                  open={openDocs}
                  orderId={selectedId}
                  clientId={client.id}
                />
                <CancelModal
                  handleClose={() => setOpenCancel(false)}
                  open={openCancel}
                  id={selectedId}
                  email={client.email}
                />
                <DeleteModal
                  id={selectedId}
                  drivers={drivers!}
                  type={'driver'}
                  open={openDriver}
                  handleClose={() => setOpenDriver(false)}
                />
              </>
            ) : (
              token && (
                <div className='client__no-booking'>
                  <div className='client__no-booking__header'>
                    <h2>No bookings found for {client?.email}.</h2>
                  </div>
                  <div className='client__no-booking__text'>
                    <p>
                      <b>Reasons could be:</b><br />
                      A typo in the email while booking.<br />
                      Check a different email address&nbsp;
                      <span onClick={() => setOpenLogin(true)}>
                        now
                      </span>.<br />
                      You may not have a booking yet.<br />
                      Contact us&nbsp;
                      <a
                        href='https://www.sprinter-rentals.com/contact'
                        target='_blank'
                      >
                        here
                      </a>
                      &nbsp;to get help.<br />
                    </p>
                  </div>
                </div>
              )
            )}
          </>
        )}
        <div className='client__info'>
          <div className='client__info__main'>
            <div className='client__info__main__container'>
              <div className='client__info__main__item'>
                <LocationOnTwoToneIcon />
                <h5>Address serve as pick-up & drop-off locations only.</h5>
              </div>
              <div className='client__info__main__item'>
                <CheckCircleTwoToneIcon />
                <h5>There is no staff on site and no vans are parked here.</h5>
              </div>
            </div>
          </div>
          <div className='client__info__secondary'>
            <div className='client__info__secondary__container'>
              <div className='client__info__secondary__item'>
                <Check />
                <h5>Pick a convinient address or get your van delivered.</h5>
              </div>
              <div className='client__info__secondary__item'>
                <Check />
                <h5>Pick-up & drop-off info is in your confirmation email.</h5>
              </div>
              <div className='client__info__secondary__item'>
                <Check />
                <h5>Get your keys after the online check-in process.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SmallFooter />
      <LoginModal open={openLogin} onClose={() => setOpenLogin(false)} />
      {/* <div className='footer'>
        <Footer />
      </div> */}
    </>
  );
};

export default ClientPage;
