import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from "@mui/material";
import Footer from 'src/components/Footer';
import Logo from 'src/components/Items/Logo';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import './styles.scss';

interface IProps {
  type: string;
}

const SuccessPage: FC<IProps> = ({ type }) => {
  const contract = useTypedSelector((state) => state.contract.contract);
  const navigate = useNavigate();

  return (
    <div className='result-page'>
      <Logo />
      <div className={`result-page__main 
        ${type === 'payment' && 'result-page__payment'}
        ${type === 'driver-invite' && 'result-page__driver-invite'}  
        ${type === 'upload' && 'result-page__upload'}`
      }>
        {type === 'driver-invite' && (
          <>
            <p className='result-page__driver-invite__title'>
              Success, that worked just fine.
            </p>
            <p>
              Your additional driver got an email from us
              to upload their documents.
            </p>
            <p>To learn more visit our&nbsp;
              <a href='https://www.sprinter-rentals.com/van-rental-made-easy'
                style={{ textDecoration: 'underline', color: '#4b566b' }}
                target='_blank'
              >Quick Guide</a> &&nbsp;
              <a href='https://www.sprinter-rentals.com/van-rental-made-easy'
                style={{ textDecoration: 'underline', color: '#4b566b' }}
                target='_blank'
              >Help Chat</a>
            </p>
          </>
        )}
        {type === 'upload' && (
          <>
            <p className='result-page__upload__title'>Thank you for uploading your documents.</p>
            <p className='result-page__upload__title'>We just emailed an invitation to add additional drivers.</p>
            <p>As your trip approaches, we will email you to:</p>
            <ul>
              <li>
                <p>E-sign your agreement.</p>
              </li>
              <li>
                <p>Make your payment.</p>
              </li>
              <li>
                <p>Receive your host's contact information.</p>
                <aside>Can't share yet, as the host may change.</aside>
              </li>
              <li>
                <p>Get pick-up or delivery details.</p>
                <aside>Can't share yet, as the host may change.</aside>
              </li>
            </ul>
            <p>To learn more visit our our&nbsp;
              <a href='https://www.sprinter-rentals.com/van-rental-made-easy'
                style={{ textDecoration: 'underline', color: '#4b566b' }}
                target='_blank'
              >Quick Guide</a> &&nbsp;
              <a href='https://www.sprinter-rentals.com/van-rental-made-easy'
                style={{ textDecoration: 'underline', color: '#4b566b' }}
                target='_blank'
              >Help Chat</a>
            </p>
          </>
        )}
        {type === 'signed' && (
          <>
            <p>
              Thank you for signing your rental agreement for your booking #
              {contract.id}.
            </p>
            <p>
              We emailed you a copy with your signed contract your records
              (printing is optional).
            </p>
            <p>
              You are now good to go. Your host will email or text you
              Instructions on where to get your van and how to unlock it.
            </p>
            <p>
              Your host {contract.host.first_name} {contract.host.last_name}
              <br />
              at {contract.host.phone} is your main point of contact during your
              rental.
            </p>
          </>
        )}
        {type === 'payment' && (
          <>
            <p>Your booking is confirmed.</p>
            <p className='result-page__payment__text'>
              Please check your confirmation email for accuracy.<br />
              Enjoy our digital check-in process.<br />
              We’ll email you along the way,&nbsp;
              <a href='https://www.sprinter-rentals.com/van-rental-made-easy'
                style={{ textDecoration: 'underline', color: '#4b566b' }}
                target='_blank'
              >here</a> a step-by-step guide.<br />
              To make changes please reply to any of our emails.<br />
              Our websites footer has a link to cancel.<br />
              We’ll email your host's info a little closer to your trip.<br />
              Hassle-free pick-up process by text messaging,<br />
              No rental counters.
            </p>
          </>
        )}
      </div>
      <p>Thank you and have a great day.</p>
      <div className='result-page_link'>
        {/* <Link to='/' children='Get more vans' /> */}
        {/* <a href='https://sprinter-rentals.com'>Home Page</a> */}
        <Button
          onClick={() => navigate('/')}
          variant='contained'
        >
          Get more vans
        </Button>
        {type === 'payment' && (
          <Button variant='contained'>
            <a href='https://www.sprinter-rentals.com/van-rental-made-easy'>
              What’s next guide
            </a>
          </Button>
        )}
        <Button variant='contained'>
          <a href='https://sprinter-rentals.com'>
            Home Page
          </a>
        </Button>
      </div>
      <div className='footer'>
        <Footer />
      </div>
    </div>
  );
};

export default SuccessPage;
