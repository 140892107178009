import { FC, useState } from 'react';
import { Tooltip } from '@mui/material';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import ClientInfoModal from './ClientInfoModal';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IVanFetch } from 'src/models/IVan';
import Icons from 'src/common/icons';
import './styles.scss';

interface IProps {
  van: IVanFetch;
  id: number
}

const ClientCard: FC<IProps> = ({ van, id }) => {

  const {
    client: { client },
  } = useTypedSelector((state) => state);
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className='client-card'>
        <div className='client__order1__main__header'>
          <h4>Your info</h4>
          <Tooltip title='Edit your info'>
            <div
              onClick={() => setOpen(true)}
              className='client__order1__main__header__edit'
            >
              <Icons.Pencil />
            </div>
          </Tooltip>
        </div>
        <div>
          <h5>
            <b>Name</b>
          </h5>
          <h5 className='client-card__name'>
            {client!.first_name} {client!.last_name}
          </h5>
        </div>
        {client!.business_name && (
          <div>
            <h5>
              <b>Business/Org</b>
            </h5>
            <h5 className='client-card__name'>
              {client!.business_name}
            </h5>
          </div>
        )}
        <div>
          <h5>
            <b>Phone</b>
          </h5>
          <div className='client-card__container'>
            <h5>{client!.phone}</h5>
          </div>
        </div>
        <div>
          <h5>
            <b>Email</b>
          </h5>
          <h5>{client!.email}</h5>
        </div>
        <div>
          <h5>
            <b>Host</b>
          </h5>
          <div className='client-card__container'>
            <h5>{van!.host.first_name} {van!.host.last_name}</h5>
            <Tooltip title='Redirect to Gmail' style={{ gridColumnStart: 2 }}>
              <a
                href={`https://mail.google.com/mail/?view=cm&source=mailto&to=${van!.host.email}&su=info for van rental number ${id}`}
                target='_blank'
                style={{ marginTop: '2px' }}
              >
                <AttachEmailIcon />
              </a>
            </Tooltip>
          </div>
        </div>
      </div>
      <ClientInfoModal
        orderId={id}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};

export default ClientCard;
