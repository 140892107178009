import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import DocumentsItem from '../../DocumentsItem';
import DeleteModal from 'src/components/Items/DeleteModal';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { IOrderForClient } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import './styles.scss';
import './media.scss';

interface IProps {
  order: IOrderForClient;
}

const DocumentsCard: FC<IProps> = ({ order }) => {

  const navigate = useNavigate();
  const {
    client: { client },
  } = useTypedSelector((state) => state);
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className='documents-card'>
        <div className='client__order1__main__header'>
          <h4>Your Documents</h4>
          <div className='client__order1__main__header__actions'>
            <Button
              children='UPLOAD DOCUMENTS'
              onClick={() => navigate(`/license/${order.licenseHash}`)}
            />
            <Tooltip title='Delete documents'>
              <div
                className='client__order1__main__header__actions__icon'
                onClick={() => setOpen(true)}
              >
                <Icons.OutlinedDelete />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className='documents-card__container'>
          <DocumentsItem
            title='Drivers License'
            links={client!.driver_license?.photos as string[] | undefined}
          />
          <DocumentsItem
            title='Proof Insurance Card'
            links={client!.driver_license?.insurance as string | undefined}
          />
        </div>
        <div className='documents-card__footer'>
          <div className='documents-card__footer__container'>
            <Icons.Shield />
            <span>Get Trip Coverage</span>
          </div>
          <Button>
            <EmailIcon />
            <a
              href={`https://mail.google.com/mail/?view=cm&source=mailto&to=${order.van?.host.email}&su=van rental number ${order.id}`}
              target='_blank'
            >
              Email us
            </a>
          </Button>
        </div>
      </div>
      <DeleteModal
        id={client!.id}
        type='documents'
        open={open}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};

export default DocumentsCard;
