import { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Skeleton } from '@mui/material';
import { IPropsOrder } from '..';
import SecondTypeButton from 'src/components/Items/button/SecondTypeButton';
import Cart from '../Cart';
import CheckoutForm from '../Stripe';
import instance from 'src/sagas/set';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { scrollToTop } from 'src/common/utils/others';
import { BookingData, IOrderReg } from 'src/models/IOrder';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY!);

const Step3: FC<IPropsOrder & { order: IOrderReg }> = ({
  order,
  onChange,
  setStep,
}) => {
  const { location, start_date, end_date, end_location, sub_location } = useTypedSelector(
    (state) => state.van.filters
  );
  const {
    extras,
    note,
    van,
    client,
    amount_seats,
    delivery_address,
    return_at,
    isPartial,
    price,
    currency,
  } = order;
  const timeOffset = DateTime.fromISO(start_date!).offset;

  const bookingData: BookingData = {
    start_date: start_date!,
    end_date: end_date!,
    location: location!,
    extras,
    note,
    client_id: client.id!,
    van_id: van?.id!,
    host_id: van?.host_id!,
    amount_seats,
    delivery_address,
    return_at,
    isPartial,
    price,
    currency,
    timeOffset,
    end_location: end_location || '',
    sub_location: sub_location || '',
    sub_location_end: sub_location || '',
    fee_list: extras,
  };
  const handleBack = () => {
    setStep(1);
    scrollToTop();
  };

  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const getClientSecret = async () => {
      const {
        data: { clientSecret },
      } = await instance.post(`${process.env.REACT_APP_API}/client/booking`, {
        bookingData,
      });
      setClientSecret(clientSecret);
    };
    order.client.id && getClientSecret();
  }, [order.client.id]);

  const options: any = {
    clientSecret,
    appearance: {
      theme: 'stripe',
    },
  };

  return (
    <>
      <div className='order-payment'>
        <div className='col-2 order-payment__container'>
          <div className='order-payment__container__cart-container'>
            <Cart
              order={order}
              titleButton='Reserve van'
              handleBackStep={handleBack}
              onChange={onChange}
            />
          </div>
          <div className='order-payment__price-off'>
            <div className='order-payment__container__payment-container'>
              <p>
                You can book now with a fully refundable down payment applied
                towards your rental.
              </p>
              <p>
                We'll email a secure link to pay your remainder before your
                rental. You may pay with the same or a different card.
              </p>
              <p>
                You may
                <a
                  href='https://www.sprinter-rentals.com/cancellation'
                  target='_blank'
                  style={{ textDecoration: 'underline' }}
                >
                  cancel
                </a>
                free up to 5 days prior to your trip.
              </p>
            </div>
            <div className='order-payment__payment'>
              {clientSecret ? (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm startDate={bookingData.start_date} />
                </Elements>
              ) : (
                <Skeleton
                  sx={{ maxWidth: `100%` }}
                  height={230}
                  style={{ transform: `none` }}
                />
              )}
              <SecondTypeButton
                children='Back'
                onClick={handleBack}
                className='order-payment__payment__back-button'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step3;
