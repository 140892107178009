import { FC } from 'react';
import { Button } from '@mui/material';
import SecondTypeButton from '../button/SecondTypeButton';

interface IProps {
  handleClose: () => void;
  handleSubmit: () => void;
  type: string;
  action: string;
}

const ModalAction: FC<IProps> = ({
  handleClose,
  handleSubmit,
  type,
  action,
}) => {

  const handleCloseForm = () => handleClose();

  return (
    <div className='col-2' style={{ marginTop: '30px' }}>
      <SecondTypeButton
        children='Cancel'
        onClick={handleCloseForm}
      />
      <Button
        variant='contained'
        onClick={handleSubmit}
        children={`${action} ${type}`}
      />
    </div>
  );
};

export default ModalAction;
