import { FC, useEffect } from 'react';
import useIsMobile from 'src/hooks/useIsMobile';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import DayHourPicker from 'src/components/Items/input/DayHourPicker';
import {
  changeISOset,
  fromISOtoFormat,
  fromJSDateToISO,
} from 'src/common/utils/dataFormat';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';

interface IProps {
  check: boolean;
  className?: string;
}

const Times: FC<IProps> = ({ check, className }) => {
  const { setStartDateFilter, setEndDateFilter } = useActions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const isMobile = useIsMobile();
  const { start_date, end_date } = useTypedSelector(
    (state) => state.van.filters
  );

  const checkLess = (start?: string, end?: string) => {
    if (start && end) {
      return start >= end;
    } else return false;
  };

  useEffect(() => {
    const nowTimePlusDay = DateTime.now()
      .plus({ hours: 24 })
      .set({ second: 0, millisecond: 0 })
      .toISO();
    checkLess(nowTimePlusDay, start_date) && (
      enqueueSnackbar('Starting in the next 24h’s? We’ll email how to call/text your host to arrange details.', {
        variant: 'info',
        persist: true,
      }),
      setTimeout(() => closeSnackbar(), 5000)
    );
  }, [start_date]);

  const setStartDate = (date?: Date, hour?: string) => {
    if (date && !hour) {
      let isoDate = fromJSDateToISO(date);
      const nowTime = DateTime.now().toFormat('D');
      if (fromISOtoFormat(isoDate, 'D') === nowTime) {
        setStartDateFilter(DateTime.now().set({ second: 0, millisecond: 0 }).toISO());
      } else {
        isoDate = changeISOset(isoDate, {
          hour: 10,
          minute: 0,
          second: 0,
        });
        setStartDateFilter(isoDate);
        if (checkLess(isoDate, end_date!)) {
          const startObj = DateTime.fromISO(isoDate)
            .plus({ hours: 2 })
            .set({ second: 0, millisecond: 0 })
            .toISO();
          setEndDateFilter(startObj);
        }
      }
    }
    if (hour) {
      const isoDate = fromJSDateToISO(date ?? DateTime.now().toISO());
      let time = hour;
      if (time === 'Noon') {
        time = '00:00 PM';
      }
      if (time === 'Midnight') {
        time = '00:00 AM';
      }

      const dayPart = time.split(' ');
      if (dayPart[1] === 'AM') {
        const time = dayPart[0].split(':');
        const changeHour = changeISOset(isoDate, {
          hour: (time[0] === '12') ? (+time[0] - 12): (+time[0]),
          minute: +time[1],
          second: 0,
        });
        setStartDateFilter(changeHour);
        if (checkLess(changeHour, end_date!)) {
          const startObj = DateTime.fromISO(changeHour)
            .plus({ hours: 2 })
            .set({ second: 0, millisecond: 0 })
            .toISO();
          setEndDateFilter(startObj);
        }
      } else {
        const time = dayPart[0].split(':');
        const changeHour = changeISOset(isoDate, {
          hour: (time[0] === '12') ? (+time[0]) : (+time[0] + 12),
          minute: +time[1],
          second: 0,
        });
        setStartDateFilter(changeHour);
        if (checkLess(changeHour, end_date!)) {
          const startObj = DateTime.fromISO(changeHour)
            .plus({ hours: 2 })
            .set({ second: 0, millisecond: 0 })
            .toISO();
          setEndDateFilter(startObj);
        }
      }
    }
  };

  const setEndDate = (date?: Date, hour?: string) => {
    
    if (checkLess(start_date, fromJSDateToISO(date)) && !hour) {
      const startObj = DateTime.fromISO(start_date!)
        .plus({ hours: 2 })
        .set({ second: 0, millisecond: 0 })
        .toISO();
      setEndDateFilter(startObj);
    } else {
      if (date && !hour) {
        let isoDate = fromJSDateToISO(date);
        const nowTime = DateTime.now().toFormat('D');
        if (fromISOtoFormat(isoDate, 'D') === nowTime) {
          if (start_date) {
            setEndDateFilter(
              DateTime.fromISO(start_date!)
                .plus({ hours: 2 })
                .set({ second: 0, millisecond: 0 })
                .toISO()
            );
          } else
            setEndDateFilter(
              DateTime.now().plus({ hours: 2 }).set({ second: 0, millisecond: 0 }).toISO()
            );
        } else if (fromISOtoFormat(isoDate, 'D') === fromISOtoFormat(start_date!, 'D')) {
          setEndDateFilter(
            DateTime.fromISO(start_date!)
              .plus({ hours: 2 })
              .set({ second: 0, millisecond: 0 })
              .toISO()
          );
        } else {
          isoDate = changeISOset(isoDate, {
            hour: 10,
            minute: 0,
            second: 0,
          });
          setEndDateFilter(isoDate);
        }
      }
      if (hour) {
        const isoDate = fromJSDateToISO(date ?? DateTime.now().toISO());
        let time = hour;
        if (time === 'Midnight') {
          time = '00:00 AM';
        }
        if (time === 'Noon') {
          time = '00:00 PM';
        }
        const dayPart = time.split(' ');
        if (dayPart[1] === 'AM') {
          const time = dayPart[0].split(':');
          const changeHour = changeISOset(isoDate, {
            hour: (time[0] === '12') ? (+time[0] - 12): (+time[0]),
            minute: +time[1],
            second: 0,
          });
          setEndDateFilter(changeHour);
          if (checkLess(start_date, changeHour)) {
            const startObj = DateTime.fromISO(start_date!)
              .plus({ hours: 2 })
              .set({ second: 0, millisecond: 0 })
              .toISO();
            setEndDateFilter(startObj);
          }
        } else {
          const time = dayPart[0].split(':');
          const changeHour = changeISOset(isoDate, {
            hour: (time[0] === '12') ? (+time[0]) : (+time[0] + 12),
            minute: +time[1],
            second: 0,
          });
          setEndDateFilter(changeHour);
          if (checkLess(start_date, changeHour)) {
            const startObj = DateTime.fromISO(start_date!)
              .plus({ hours: 2 })
              .set({ second: 0, millisecond: 0 })
              .toISO();
            setEndDateFilter(startObj);
          }
        }
      }
    }
  };

  return (
    <div className={className ? className : 'filter__time'}>
      <div className='data-picker'>
        <div className='filter__title'>Date From</div>
        <DayHourPicker
          date={start_date}
          setDate={setStartDate}
          isMobile={isMobile}
          check={check}
        />
      </div>
      {className !== 'singleVan__availability__formContainer__times' && <div className='dash'>-</div>}
      <div className='data-picker'>
        <div className='filter__title'>Date Drop</div>
        <DayHourPicker
          date={end_date}
          setDate={setEndDate}
          minLimit={start_date!}
          isMobile={isMobile}
          check={check}
        />
      </div>
    </div>
  );
};

export default Times;
