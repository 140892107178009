import { FC, useEffect, useRef, useState } from 'react';
import { IOrderForClient } from 'src/models/IOrder';
import './styles.scss';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import { Button, ClickAwayListener, Tooltip } from '@mui/material';
import { useActions } from 'src/hooks/useActions';
import Icons from 'src/common/icons';

interface IProps {
  order: IOrderForClient
}

const NoteCard: FC<IProps> = ({ order }) => {

  const { id, note } = order;
  const { updateOrder } = useActions();
  const [newNote, setNewNote] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setNewNote(note);
  }, [note]);

  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div className='note-card'>
        <div className='note-card__header'>
          <div className='note-card__header__title'>
            <h4>Note</h4>
            <h6>
              We are return customers... thank you.
            </h6>
          </div>
          <Tooltip title='Edit note'>
            <div
              onClick={() => setShow(true)}
              className='client__order1__main__header__edit'
            >
              <Icons.Pencil />
            </div>
          </Tooltip>
        </div>
        {show ? (
          <>
            <SimpleInput
              data={newNote}
              setData={setNewNote}
              placeholder='Enter note'
              variant='outlined'
              length={500}
              multiline
              rows={5}

            />
            <Button
              children='Save'
              onClick={() => updateOrder({ id: id, note: newNote })}
              disabled={note === newNote}
            />
          </>
        ) : (
          <>
            <div
              onClick={() => setShow(!show)}
              className='note-card__edit'
            >
              {note}
            </div>
          </>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default NoteCard;
