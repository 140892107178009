import { Currency } from "dinero.js";
import { IClient, IClientFetch, IExtra, IOrder } from "src/models/IOrder";
import { IVanFetch } from "src/models/IVan";

export enum OrderActionEnum {
  SETUP_ORDER = "SETUP_ORDER",
  CLEAR_ORDER = "CLEAR_ORDER",

  INIT_ORDER = "INIT_ORDER",
  CHANGE_ORDER = "CHANGE_ORDER",

  REQUEST_BOOK_ORDER = "REQUEST_BOOK_ORDER",
  RESULT_BOOK_ORDER = "RESULT_BOOK_ORDER",

  REQUEST_ADD_CLIENT = "REQUEST_ADD_CLIENT",
  RESULT_ADD_CLIENT = "RESULT_ADD_CLIENT",
}
export interface OrderState {
  client: IClient | IClientFetch;
  van: IVanFetch;
  price: number;
  currency: Currency;
  extras: IExtra[];
  note: string;
  voucher?: string;
  result?: {
    client?: string | number;
  };
  isPartial: boolean;
}
export interface InitOrderAction {
  type: OrderActionEnum.INIT_ORDER;
}
export interface ChangeOrderAction {
  type: OrderActionEnum.CHANGE_ORDER;
  name: string;
  value: any;
}
export interface SetupOrderAction {
  type: OrderActionEnum.SETUP_ORDER;
  van: IVanFetch;
}
export interface ClearOrderAction {
  type: OrderActionEnum.CLEAR_ORDER;
}
export interface RequestAddClientAction {
  type: OrderActionEnum.REQUEST_ADD_CLIENT;
  client: IClient;
}
export interface ResultAddClientAction {
  type: OrderActionEnum.RESULT_ADD_CLIENT;
  status: string | number;
  client: IClientFetch;
}

export interface RequestBookOrderAction {
  type: OrderActionEnum.REQUEST_BOOK_ORDER;
  dataOrder: IOrder;
}
export interface ResultBookOrderAction {
  type: OrderActionEnum.RESULT_BOOK_ORDER;
  statusText: string;
}

export type OrderAction =
  | InitOrderAction
  | SetupOrderAction
  | ChangeOrderAction
  | ClearOrderAction
  | RequestAddClientAction
  | ResultAddClientAction
  | RequestBookOrderAction
  | ResultBookOrderAction;
