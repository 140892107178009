import { Modal } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import SwiperCore, { Navigation, Thumbs } from "swiper";
import { colorSet } from "src/theme";
import CloseIcon from "@mui/icons-material/Close";
import "../../styles.scss";
SwiperCore.use([Navigation, Thumbs]);

interface IProps {
  open: boolean;
  handleClose: any;
  imgs: string[];
  index: number;
}
const GalleryModal: FC<IProps> = ({ open, handleClose, imgs, index }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    setThumbsSwiper(null);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      sx={{
        ".MuiBackdrop-root": {
          background: "rgba(0, 0, 0, 0.9)",
        },
      }}
    >
      <>
        <div className="vans-gallery">
          <CloseIcon
              className='closeIcon'
              onClick={handleClose}
          />
          <Swiper
            style={{
              //@ts-ignore
              "--swiper-navigation-color": colorSet.primaryWhite,
              //@ts-ignore
              "--swiper-pagination-color": colorSet.primaryWhite,
            }}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            initialSlide={index}
            className="vans-gallery__swiper1"
          >
            {imgs.map((link, index) => (
              <SwiperSlide key={index}>
                <img src={link} />
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            //@ts-ignore
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            initialSlide={index}
            watchSlidesProgress={true}
            className="vans-gallery__swiper2"
          >
            {imgs.map((link, index) => (
              <SwiperSlide key={index}>
                <img src={link} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </>
    </Modal>
  );
};

export default GalleryModal;
