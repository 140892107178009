import { FC } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { dineroFormat, dineroSubtractPrice } from 'src/common/utils/dataFormat';
import { IOrderForClient } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import './styles.scss';
import './media.scss';

interface IProps {
  order: IOrderForClient
}

const PaymentsCard: FC<IProps> = ({ order }) => {

  const { payNow } = useActions();
  const {
    client: { isLoading },
  } = useTypedSelector((state) => state);
  const {
    id,
    price,
    currency,
    partial_paid,
  } = order;

  return (
    <div className='payments-card'>
      <div className='client__order1__main__header'>
        <h4>Payments</h4>
        {/* <Icons.Pencil /> */}
      </div>
      <div className='payments-card__container'>
        <p>
          You can settle up now.<br />
          Please Pay 72h before, due 24h before.
        </p>
        <div>
          <span className='payments-card__title'>
            Total incl Fees:
          </span>
          <span className='payments-card__price blue'>
            {dineroFormat(price, currency)}
          </span>
        </div>
        <div>
          <span className='payments-card__title'>
            Down-payment:
          </span>
          <span className='payments-card__price green'>
            {dineroFormat(partial_paid, currency)}
          </span>
        </div>
      </div>
      <div className='payments-card__footer'>
        <div>
          <span className='payments-card__title'>
            Open Balance:
          </span>
          <span className='payments-card__price red'>
            {dineroSubtractPrice(price, partial_paid, currency)}
          </span>
        </div>
        <Button
          onClick={() => payNow(id)}
          children={isLoading.pay ? <CircularProgress size={15} /> : 'Settle Now'}
        />
      </div>
    </div>
  );
};

export default PaymentsCard;
