import { FC } from 'react';
import { Tooltip } from '@mui/material';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import Check from 'src/pages/Van/item/CheckIcon';
import Icons from 'src/common/icons';
import './styles.scss';

interface IProps {
  title: string;
  links?: string | string[]
  handleClick?: () => void
}

const DocumentsItem: FC<IProps> = ({ title, links, handleClick }) => {

  const { showContract, showLicense, showInsurance } = useActions();
  const { client } = useTypedSelector((state) => state.client);

  return (
    <div className='doc-item'>
      <div className='doc-item__container'>
        <span className='doc-item__title'>{title}</span>
        <div className='doc-item__icons'>
          {Array.isArray(links) ? (
            links.map((link, index) => (
              <Tooltip title={title} key={index}>
                {title.toLowerCase().includes('insurance card') ? (
                  <div
                    className='doc-item__icons__icon'
                  // onClick={() => showInsurance(client!.driver_license!.s3_keys[2])}
                  >
                    <Check />
                  </div>
                ) : (
                  <div
                    className='doc-item__icons__icon'
                  // onClick={() => showLicense(client!.driver_license!.s3_keys[index])}
                  >
                    <Check />
                  </div>
                )}
              </Tooltip>
            ))
          ) : links ? (
            <Tooltip title={title}>
              {links.startsWith('https://') ? (
                <a
                  href={links}
                  target='_blank'
                  className='icon_stroke'
                >
                  <Icons.LilPic />
                </a>
              ) : (
                <div
                  className='icon_stroke'
                  onClick={() => showContract(links)}
                >
                  <Icons.LilPic />
                </div>
              )}
            </Tooltip>
          ) : (
            <span className='doc-item__text'>missing</span>
          )}
        </div>
      </div>
      {/* <Tooltip title={`Delete ${title}`}>
        <div
          className='doc-item__delete'
          onClick={handleClick}
        >
          <Icons.OutlinedDelete />
        </div>
      </Tooltip> */}
    </div>
  );
};

export default DocumentsItem;
