import { ChangeEvent, FC, useState } from 'react';
import { Backdrop, Modal } from '@mui/material';
import ModalAction from 'src/components/Items/Actions';
import SimpleMultiSelect from 'src/components/Items/select/SimpleMultiSelect';
import { useActions } from 'src/hooks/useActions';
import './styles.scss';

interface IProps {
  handleClose: () => void;
  open: boolean;
  orderId: number;
  clientId: number;
}
const DocumentsModal: FC<IProps> = ({ handleClose, open, orderId, clientId }) => {

  const { updateClient, updateOrder } = useActions();
  const [document, setDocument] = useState<string[]>([]);
  const documentArray = ['license', 'contract'];

  const handleChange =
    (type: any) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      type(typeof value === 'string' ? value.split(',') : value);
    };

  const handleSubmit = (value: string[]) => {
    if (value.length) {
      if (value.includes('license')) {
        updateClient({
          id: clientId,
          driver_license_id: null,
        });
      } else if (value.includes('contract')) {
        updateOrder({ id: orderId, pdf_order: '' });
      }
    }
    handleClose();
  };

  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={handleClose}
    >
      <div className='documents-modal'>
        <div className='documents-modal__title'>Delete documents</div>
        <div style={{ marginBottom: '40px' }}>
          <SimpleMultiSelect
            dataArray={documentArray}
            title='documents'
            data={document}
            setData={handleChange(setDocument)}
            gridColumns={1}
          />
        </div>
        <ModalAction
          handleClose={handleClose}
          type='document'
          action='Delete'
          handleSubmit={() => handleSubmit(document)}
        />
      </div>
    </Modal>
  );
};
export default DocumentsModal;
