import { FC, useEffect, useState } from 'react';
import { Backdrop, Modal } from '@mui/material';
import ModalAction from 'src/components/Items/Actions';
import SimpleInput from 'src/components/Items/input/SimpleInput';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { useActions } from 'src/hooks/useActions';
import { IErrorsClientData } from 'src/pages/Order/Stepper/Step2';
import { validateField } from 'src/common/utils/validation';
import './styles.scss';
import './media.scss';

interface IProps {
  orderId: number;
  open: boolean;
  handleClose: () => void;
}
const ClientInfoModal: FC<IProps> = ({ orderId, handleClose, open }) => {

  const { updateClient, updateOrder } = useActions();
  const {
    client: { client }
  } = useTypedSelector((state) => state);
  const [currentClient, setCurrentClient] = useState(client);
  const [errors, setErrors] = useState<IErrorsClientData>({});

  useEffect(() => {
    setCurrentClient(client);
  }, [open]);

  const handleInput = (name: string) => (value: string | number) =>
    setCurrentClient({ ...currentClient!, [name]: value });

  const validateInputs = (): boolean => {
    const first_name = validateField(currentClient!.first_name);
    const last_name = validateField(currentClient!.last_name);
    const email = validateField(currentClient!.email);

    setErrors({
      first_name,
      last_name,
      email,
    });
    return [first_name, last_name, email].every((el) => !el);
  };

  const handleSubmit = () => {
    if (validateInputs()) {
      const updatedClient = {
        first_name: currentClient!.first_name.trim(),
        last_name: currentClient!.last_name.trim(),
        email: currentClient!.email.toLowerCase().trim(),
        phone: currentClient!.phone.trim(),
        business_name: currentClient!.business_name ? currentClient!.business_name.trim() : ''
      };
      for (const key in currentClient) {
        currentClient[key as keyof typeof currentClient] === client![key as keyof typeof client] &&
          delete updatedClient![key as keyof typeof updatedClient];
      }
      updateClient({ ...updatedClient, id: client!.id });
      updatedClient.email &&
        updateOrder({
          id: orderId,
          client_email: updatedClient.email
        });
    }
  };

  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={handleClose}
    >
      <div className='client-info-modal'>
        <div className='documents-modal__title'>Update your info</div>
        <div className='col-2 client-info-modal__container'>
          <SimpleInput
            data={currentClient!.first_name}
            setData={handleInput('first_name')}
            title='First name'
            placeholder='Enter your first name'
            length={60}
            error={errors.first_name}
            isHelperText
          />
          <SimpleInput
            data={currentClient!.last_name}
            setData={handleInput('last_name')}
            title='Last name'
            placeholder='Enter your last name'
            length={60}
            error={errors.last_name}
            isHelperText
          />
          <SimpleInput
            data={currentClient!.email}
            setData={handleInput('email')}
            title='Email'
            placeholder='Enter your email'
            length={60}
            error={errors.email}
            isHelperText
          />
          <SimpleInput
            data={currentClient!.phone}
            setData={handleInput('phone')}
            title='Phone'
            placeholder='Enter your phone'
            type='number'
            length={60}
          />
          <SimpleInput
            data={currentClient!.business_name || ''}
            setData={handleInput('business_name')}
            title='Business/org'
            placeholder='Enter your business/org name'
            length={60}
          />
        </div>
        <ModalAction
          type='your info'
          action='Update'
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      </div>
    </Modal>
  );
};
export default ClientInfoModal;
