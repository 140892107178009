import { FC, useState, useEffect } from "react";
import { IVanFetch } from "src/models/IVan";


interface IProps {
    data: IVanFetch[];
    renderItem: (item: IVanFetch) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    show: number;
    setShow: (show: number) => void;
}

const InfiniteScrollList: FC<IProps> = ({ data, renderItem, loading, setLoading, show, setShow }) => {

  const [list, setList] = useState(data.slice(0, 10));

  useEffect(() => {
    if (!loading) return;
    if (show >= data.length) return setLoading(false);
    setTimeout(() => {
      setList(list.concat(data.slice(show, show + 10)));
      setShow(show + 10);
      setLoading(false);
    }, 1000);
  }, [loading]);

  return (
    <>
      {list.map(renderItem)}
    </>
  );
};
export default InfiniteScrollList;