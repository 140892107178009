import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';
import SmallHeader from '../Van/singleVanPage/SmallHeader';
import Footer from 'src/components/Footer';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';

const LoginPage: FC = () => {

  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { checkToken } = useActions();
  const { result } = useTypedSelector((state) => state.client);
  const token = params.get('token');

  useEffect(() => {
    if (token) checkToken(token);
  }, [token]);

  useEffect(() => {
    if (result.check === 200) {
      navigate('/client');
      enqueueSnackbar('Authorization was successful', {
        variant: 'success',
      });
    } else if (result.check === 400) {
      navigate('/');
      enqueueSnackbar('Authorization failed', {
        variant: 'error',
      });
    }
  }, [result.check]);

  return (
    <>
      <div className='header'>
        <SmallHeader />
      </div>
      <div style={{ width: '100%', textAlign: 'center', padding: '200px 0' }}>
        <CircularProgress size={100} />
      </div>
      <div className='footer'>
        <Footer />
      </div>
    </>
  );
};

export default LoginPage;
