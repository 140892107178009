import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime, Interval } from 'luxon';
import { Button, CircularProgress, Tooltip } from '@mui/material';

import Step from './components/Step';
import DeleteModal from 'src/components/Items/DeleteModal';
import DocumentsItem from './components/DocumentsItem';
import DriverItem from './components/DriverItem';
import ArticlesCard from './components/Cards/Articles';
import ClientCard from './components/Cards/Client';
import CommonCard from './components/Cards/Common';
import DocumentsCard from './components/Cards/Documents';
import DriversCard from './components/Cards/Drivers';
import PaymentsCard from './components/Cards/Payments';
import NoteCard from './components/Cards/Note';
import useIsMobile from 'src/hooks/useIsMobile';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { dineroFormat, dineroSubtractPrice } from 'src/common/utils/dataFormat';
import { IOrderForClient } from 'src/models/IOrder';
import Icons from 'src/common/icons';
import '../../styles.scss';
import '../../media.scss';

interface IProps {
  order: IOrderForClient,
  handleOpenDriver: (orderId: number, driverId: number) => void,
  handleOpenDocs: (id: number) => void,
  handleOpenCancel: (id: number) => void,
}

const OrderCard: FC<IProps> = ({
  order,
  handleOpenDriver,
  handleOpenDocs,
  handleOpenCancel
}) => {

  const { payNow } = useActions();
  const navigate = useNavigate();
  const {
    client: { client, orderList, isLoading },
  } = useTypedSelector((state) => state);
  const [openLicense, setOpenLicense] = useState(false);
  const [openContract, setOpenContract] = useState(false);

  const {
    id,
    status,
    created_at,
    timeOffset,
    start_date,
    location,
    sub_location,
    end_date,
    end_location,
    sub_location_end,
    delivery_address,
    return_at,
    van,
    price,
    currency,
    partial_paid,
  } = order;

  return (
    <>
      <div className='client__order1'>
        <div className='client__order1__header'>
          <h3>Upcoming Trip: {id}</h3>
          <h6>See All Trips Below</h6>
          <div className='client__order1__header__badges'>
            <div className='client__order1__header__badge'>
              <Icons.ReservedVan />
              <span>vehicle is reserved</span>
            </div>
            <div className='client__order1__header__badge'>
              <span>STATUS:</span>
              <div className={`client__order1__header__badge__status ${status}`}>
                {status}
              </div>
            </div>
          </div>
        </div>
        <div className='client__order1__steps'>
          <h3>Take Next Steps for Check-in</h3>
          <div className='client__order1__steps__container'>
            <Step
              title='Upload Docs'
              status={
                (!!client!.driver_license?.insurance
                  && client!.driver_license?.photos?.length === 2
                ) ? 'completed' : 'complete'
              }
              statusText={
                (!!client!.driver_license?.insurance
                  && client!.driver_license?.photos?.length === 2
                ) ? 'completed' : 'complete now'
              }
              handleClick={
                (!!client!.driver_license?.insurance
                  && client!.driver_license?.photos?.length === 2)
                  ? () => { }
                  : () => navigate(`/license/${order.licenseHash}`)
              }
            />
            <Step
              title='Invite Drivers'
              status={
                order.additional_driver.length ? 'completed' : 'complete'
              }
              statusText={
                order.additional_driver.length ? 'invite more' : 'invite now'
              }
              handleClick={() => navigate(`/driver/add/${order.inviteDriverHash}`)}
            />
            <Step
              title='Payment'
              status={
                (!client!.driver_license?.insurance
                  && (
                    !client!.driver_license?.photos
                    || client!.driver_license?.photos.length < 2
                  )
                ) ? 'closed'
                  : order.status === 'paid'
                    ? 'completed'
                    : 'complete'
              }
              statusText={
                (!client!.driver_license?.insurance
                  && (
                    !client!.driver_license?.photos
                    || client!.driver_license?.photos.length < 2
                  )
                ) ? 'available later'
                  : order.status === 'paid'
                    ? 'completed'
                    : 'pay now'
              }
              handleClick={
                order.status === 'paid'
                  || (!client!.driver_license?.insurance
                    && (
                      !client!.driver_license?.photos
                      || client!.driver_license?.photos.length < 2
                    )
                  ) ? () => { } : () => payNow(order.id)
              }
            />
            <Step
              title='E-sign'
              status={
                (
                  Interval.fromDateTimes(
                    DateTime.now(),
                    DateTime.fromISO(order.start_date)
                  ).toDuration(["days"]).days > 2
                    ? 'closed'
                    : order.pdf_order
                      ? 'completed'
                      : 'complete'
                )
              }
              statusText={
                (
                  Interval.fromDateTimes(
                    DateTime.now(),
                    DateTime.fromISO(order.start_date)
                  ).toDuration(["days"]).days > 2
                    ? 'available later'
                    : order.pdf_order
                      ? 'completed'
                      : 'complete now'
                )
              }
              handleClick={
                (Interval.fromDateTimes(
                  DateTime.now(),
                  DateTime.fromISO(order.start_date)
                ).toDuration(["days"]).days > 2) || order.pdf_order
                  ? () => { }
                  : () => navigate(`/contract/${order.signRaHash}`)
              }
            />
            <Step
              title='Pickup info & keys'
              status={
                (Interval.fromDateTimes(
                  DateTime.now(),
                  DateTime.fromISO(order.start_date)
                ).toDuration(["days"]).days > 2) || !order.pdf_order
                  ? 'closed'
                  : 'completed'
              }
              statusText={
                (Interval.fromDateTimes(
                  DateTime.now(),
                  DateTime.fromISO(order.start_date)
                ).toDuration(["days"]).days > 2) || !order.pdf_order
                  ? 'available later'
                  : 'completed'
              }
              handleClick={() => { }}
            />
          </div>
        </div>
        <div className='client__order1__main'>
          <h3>Manage Your Booking.</h3>
          <div className='client__order1__main__cards'>
            <CommonCard order={order} />
            <ClientCard id={id} van={van!} />
            <PaymentsCard order={order} />
            <ArticlesCard order={order} />
            <DocumentsCard order={order} />
            <DriversCard order={order} vanId={van!.id} />
            <NoteCard order={order} />
          </div>
        </div>
      </div>
      {/* <div className='client__order'>
        <div className='client__order__title'>
          <h4>Order #{order.id}</h4>
        </div>
        <div className='client__order__main'>
          <div className='client__order__main__info'>
            <h5>
              <b>Status:</b>
              {order.status}
            </h5>
            <h5>
              <b>Booked:</b>
              {
                DateTime.fromISO(order.created_at, { zone: "UTC" })
                  .plus({ minutes: order.timeOffset }).toFormat("ff")
              }
            </h5>
            <h5>
              <b>From:</b>
              {
                `${DateTime.fromISO(order.start_date, { zone: "UTC" })
                  .plus({ minutes: order.timeOffset }).toFormat("ff")} 
                    | ${order.location} ${order.sub_location ? ` | ${order.sub_location}` : ''
                }`
              }
            </h5>
            <h5>
              <b>To:</b>
              {
                `${DateTime.fromISO(order.end_date, { zone: "UTC" })
                  .plus({ minutes: order.timeOffset }).toFormat("ff")} 
                    | ${order.end_location || order.location} ${order.sub_location_end ? ` | ${order.sub_location_end}` : ''
                }`
              }
            </h5>
            {order.delivery_address && (
              <>
                <h5>
                  <b>Deliver to:</b>
                  {order.delivery_address}
                </h5>
                <h5>
                  <b>Return at:</b>
                  {order.return_at ? order.return_at : order.delivery_address && "same as delivery"}
                </h5>
              </>
            )}
            <h5>
              <b>Host:</b><span>{order.van?.host.first_name} {order.van?.host.last_name}</span>
            </h5>
            <h5>
              <b>Host Phone:</b>{order.van?.host.phone}
            </h5>
          </div>
          <div className='client__order__main__van'>
            <img src={order.van!.photos?.exterior[0]} alt='van' />
            <h5>
              {order.van!.mark}
              <a
                href={`https://van.sprinter-rentals.com/van/143${order.van_id}`}
                target="_blank"
              >
                here
              </a>
            </h5>
          </div>
        </div>
        <div className='client__order__container'>
          <div className='client__order__container__payment'>
            <h4>Payments</h4>
            <div className='client__order__container__payment__main'>
              <div className='client__order__container__payment__items'>
                <h5>
                  <b>Partial:</b>
                  <span className='green'>
                    {dineroFormat(order.partial_paid, order.currency)}
                  </span>
                </h5>
                <h5>
                  <b>Balance:</b>
                  <span className='red'>
                    {dineroSubtractPrice(order.price, order.partial_paid, order.currency)}
                  </span>
                </h5>
                <h5>
                  <b>Total:</b>
                  <span className='blue'>
                    {dineroFormat(order.price, order.currency)}
                  </span>
                </h5>
              </div>
              <Button
                onClick={() => payNow(order.id)}
                children={isLoading.pay ? <CircularProgress size={15} /> : 'Pay Now'}
              />
            </div>
          </div>
          <div className='client__order__container__drivers'>
            <h4>Additional Drivers</h4>
            <div className='client__order__container__drivers__main'>
              <div className='client__order__container__drivers__main__items'>
                {order.additional_driver.map((driver, index) => (
                  <div className='client__order__container__drivers__main__item'>
                    <div
                      key={index}
                      className='client__order__container__drivers__item__title'
                    >
                      {`${driver.first_name} ${driver.last_name}`}
                    </div>
                    <Tooltip title='Delete driver'>
                      <div
                        onClick={() => handleOpenDriver(order.id, driver.id)}
                        className='icons-container'
                      >
                        <Icons.OutlinedDelete />
                      </div>
                    </Tooltip>
                  </div>
                ))}
              </div>
              <Button
                onClick={() => navigate(`/driver/add/${order.inviteDriverHash}`)}
                children='Invite Drivers'
              />
            </div>
          </div>
          <div className='client__order__container__documents'>
            <div className='client__order__container__documents__header'>
              <h4>Documents</h4>
              <Tooltip title='Delete documents'>
                <div
                  onClick={() => handleOpenDocs(order.id)}
                  className='icons-container'
                >
                  <Icons.OutlinedDelete />
                </div>
              </Tooltip>
            </div>
            <div className='client__order__container__documents__main'>
              <div className='client__order__container__documents__items'>
                <DocumentsItem
                  title='Drivers license'
                  links={client!.driver_license?.photos as string[] | undefined}
                />
                <DocumentsItem
                  title='Insurance card'
                  links={client!.driver_license?.insurance as string | undefined}
                />
                <DocumentsItem
                  title='Signed contract'
                  links={order.pdf_order}
                />
              </div>
              <div className='client__order__container__documents__main__actions'>
                <Button
                  onClick={() => navigate(`/license/${order.licenseHash}`)}
                  children='Upload DL'
                  disabled={
                    !!client!.driver_license?.insurance
                    && client!.driver_license?.photos?.length === 2
                  }
                />
                <Button
                  onClick={() => navigate(`/contract/${order.signRaHash}`)}
                  children='Sign RA'
                  disabled={
                    (order.pdf_order ||
                      Interval.fromDateTimes(
                        DateTime.now(),
                        DateTime.fromISO(orderList![0].start_date)
                      ).toDuration(["days"]).days > 2
                    ) ? true : false
                  }
                  sx={{ width: '100%' }}
                />
                {Interval.fromDateTimes(
                  DateTime.now(),
                  DateTime.fromISO(orderList![0].start_date)
                ).toDuration(["days"]).days > 2 && (
                    <h5>Due later: sign 48h before your trip</h5>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <div className='client__order__footer'>
          <h5>
            <Tooltip title='Redirect to Gmail'>
              <a
                href={`https://mail.google.com/mail/?view=cm&source=mailto&to=${order.van?.host.email}&su=van rental number ${order.id}`}
                target='_blank'
              >
                Email us
              </a>
            </Tooltip>
            to request a change
          </h5>
          <h5>
            <a
              href='https://www.sprinter-rentals.com/help'
              target='_blank'
            >
              Get help
            </a>
          </h5>
          <div
            className='client__order__footer__help'
            onClick={() => handleOpenCancel(order.id)}
          >
            Cancel Now
          </div>
        </div>
      </div> */}
      <DeleteModal
        id={client!.id}
        type='license'
        open={openLicense}
        handleClose={() => setOpenLicense(false)}
      />
      <DeleteModal
        id={id}
        type='insurance'
        open={openContract}
        handleClose={() => setOpenContract(false)}
      />
    </>
  );
};

export default OrderCard;
